import React from "react"

const Chip = () => (
  <div className=" w-32 h-32 lg:w-128 lg:h-128 rounded relative flex items-center justify-center pb-16 lg:pt-24 lg:pb-24 lg:py-0 z-50">
    <div
      id="board"
      className="flex items-end justify-center shadow-lg rounded-xl lg:rounded-3xl"
    >
      <div
        id="circle"
        className="w-24 h-24 lg:w-96 lg:h-96 bg-brand-teal rounded-xl lg:rounded-3xl shadow-lg z-10 flex items-center justify-center relative overflow-hidden"
      >
        <div id="layers" className="absolute w-full z-40 inset-y-0 right-0">
          <div
            id="fold"
            className="absolute inset-y-0 right-0 z-20 transition-1/2 w-2/5"
          ></div>
          <div
            id="chip"
            className="hidden lg:flex absolute inset-y-0 left-0 z-10 w-3/5 items-end justify-end pr-6"
          >
            <div className="w-2 h-48 mr-1 shadow-md"></div>
            <div className="w-2 h-48 mr-1 shadow-md"></div>
            <div className="w-2 h-48 mr-4 shadow-md"></div>
          </div>
        </div>
        <div className="period hidden lg:block absolute w-4 h-4 rounded-full top-0 left-0 ml-10 mt-10 z-10 shadow"></div>
        <div className="period w-4 h-4 rounded-full hidden lg:block absolute bottom-0 left-0 ml-16 mb-16 z-10 shadow"></div>
        <div className=" period w-4 h-4 rounded-full hidden lg:block absolute top-0 right-0 mr-48 mt-16 z-10 shadow"></div>
        <div className="absolute inset-0 circuit z-0"></div>
        <div id="logo" className="z-50 flex items-end justify-center">
          <div className="w-12 h-12 lg:w-48 lg:h-48 bg-white shadow-lg rounded-full z-10 flex items-center justify-center">
            <div className="w-6 h-6 lg:w-24 lg:h-24 bg-brand-teal rounded-full shadow-md"></div>
          </div>
          <div className="w-3 h-3 lg:w-10 lg:h-10 bg-brand-teal rounded-full shadow-inner lg:mb-2 overflow-hidden relative">
            <div className="absolute inset-0 circuit z-0"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Chip
