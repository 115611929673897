import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Button = ({ color, children, href, openInNewWindow, ...props }) => {
  let classes = ""
  if (color === "dark") classes = "text-white bg-brand-grey-dark"
  if (color === "white") classes = "text-brand-grey-dark bg-white"

  if (openInNewWindow) {
    props.target = "_blank"
    props.rel = "noopener noreferrer"
  }

  return (
    <a
      {...props}
      href={href}
      className={`"block w-full md:w-auto px-6 py-3  rounded-lg no-underline shadow-md  transition-1/4 hover:shadow-lg font-medium ${classes}`}
    >
      {children}
    </a>
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(["dark", "white"]),
  href: PropTypes.string,
  children: PropTypes.string,
}

Button.defaultProps = {
  color: "dark",
  href: "",
  children: "",
}

export default Button
