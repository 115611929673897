import React from "react"

const Feature = ({ title, description, children }) => (
  <div className="w-full text-brand-secondary py-4 md:py-8  px-6">
    <div className="w-full  md:flex justify-start">
      <div className="flex items-center justify-center md:block md:mr-8">
        {children}
      </div>
      <div>
        <h2 className="text-brand-blue-dark font-semibold text-lg text-center md:text-left mb-2 md:mb-0">
          {title}
        </h2>
        <p className="leading-normal mt-2 text-sm text-left">{description}</p>
      </div>
    </div>
  </div>
)

export default Feature
